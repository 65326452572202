<template>
    <!-- <label v-if="array">
    {{ array }}</label> -->
    
    <div style="display: flex;">
        <div style="width:10%; margin-top: 2%;">
            <label >Market Values </label>
        </div>
        
        <div class="container" style="background-color: white; position: relative; margin-bottom: 3rem;">
            <div
                style="
                    position: absolute; 
                    top: 35%; 
                    left:4%; 
                    display: flex; 
                    flex-direction: column; 
                    gap: 0.7rem;
                    "
                >
                <label><strong>$</strong></label>
                <label><strong>#</strong></label>
            </div>


            <Button @click="prev" icon="pi pi-angle-left"  style="
                position: absolute;
                left: 2.5%;
                top: -22%" 
            />
            <!-- Values -->
            <div style="
                display: flex; 
                justify-content: space-between;
                align-items: center;
                margin-left: 5%;
                margin-right: 5%;">
                <div v-for="(item, index) in array[p - 1]" :key="index" style=" 
                    width: 30%; 
                    height: 20%; 
                    display:flex; 
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                ">
                    <div style="display:grid; place-items: center;">
                        <div>
                            <h5>{{ item.year }}</h5>
                        </div>
                        <InputText 
                            type="text" 
                            class="summaryText"
                            style="width: 90%; 
                            text-align: right;" 
                            @input="changeValue($event, index)"
                            v-model:value="array[p - 1][index].marketValue" 
                        />
                    </div>
                </div>
            </div>

            <!-- Units -->
            <div style="
                display: flex; 
                justify-content: space-between;
                align-items: center;
                margin-left: 5%;
                margin-right: 5%;">
                <div v-for="(item, index) in array[p - 1]" :key="index" style=" 
                    width: 30%; 
                    height: 20%; 
                    display:flex; 
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                ">
                    <div style="display:grid; place-items: center;">
                        <InputText 
                            type="number" 
                            class="summaryText"
                            style="width: 90%; 
                            text-align: right;" 
                            @input="changeUnit($event, index)"
                            v-model:value="array[p - 1][index].unit" 
                        />
                    </div>
                </div>
            </div>

            <Button @click="next" icon="pi pi-angle-right" style="
                position: absolute;
                right: 2.5%;
                top: -22%;
            "  />
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

export default {
    name: 'Carousel',
    components: {
        Button,
        InputText,
    },
    emits:['getMarketValue'],
    props:[
        'marketValues', 
        'index',
    ],
    computed: {
    },
    async mounted() {
        // add table for initial
        this.getSummary();
        this.$emit("getMarketValue",  {object: this.array , index: this.index })
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            showArray: [],
            array: [],
            pArray: [],
            p: 1,
            //array diff was 4 for 5 elements
            arrayDiff: 9,
            tabla: [],
        };
    },
    methods: {
        getSummary(){
            if(!this.marketValues ||this.marketValues.length === 0){
                for (let i = 0; i <= this.arrayDiff; i++) {
                    const marketValues = {
                        "unit": 0,
                        "marketValue": 0,
                        "year": this.currentYear + i,
                    }
                    this.pArray.push(marketValues)
                    if (i === this.arrayDiff) {
                        this.array.push(this.pArray)
                    }
                }
            }
            else{
                const numbers = this.marketValues;
                numbers.sort((a,b) => a.year - b.year)
                let a =[]
                for(let i = 0; i <= numbers.length; i++){
                    a.push(numbers[i])
                    if(a.length === 10){
                        this.array.push(a);
                        a = []
                    }
                }
            }
        },
        next() {
            if (this.array.length === this.p) {
                const lastYear = this.array[this.p - 1][this.array[this.p - 1].length - 1].year + 1;
                const pArray = []
                for (let i = 0; i <= this.arrayDiff; i++) {
                    const marketValues = {
                        "unit":0,
                        "marketValue": 0,
                        "year": lastYear + i,
                    }
                    pArray.push(marketValues)
                    if (i === this.arrayDiff) {
                        this.array.push(pArray)
                    }
                }
                this.p = this.p + 1
                // this.$emit("getMarketValue", this.array)
                this.$emit("getMarketValue",  {object: this.array , index: this.index })
            }
            else {
                this.p = this.p + 1
            }
        },
        prev() {
            if (1 === this.p) {
                const firstYear = this.array[this.p - 1][0].year //2022
                const prevRangeFirstYear = firstYear - (this.arrayDiff + 1)//2017 2022- diffYear
                const pArray = []
                for (let i = 0; i <= this.arrayDiff; i++) {
                    const marketValues = {
                        "unit":0,
                        "marketValue": 0,
                        "year": prevRangeFirstYear + i,
                    }
                    pArray.push(marketValues)
                    if (i === this.arrayDiff) {
                        this.array.splice(0, 0, pArray);
                    }
                }
                // this.$emit("getMarketValue", this.array)
                this.$emit("getMarketValue",  {object: this.array , index: this.index })
            }
            else {
                this.p = this.p - 1
            }
        },
        changeValue(event, index) {
            const numericValues = event.target.value.replace(/[^\d.]|[.](?=.*[.])|(\.\d{4,})/g , '');
            this.array[this.p - 1][index].marketValue = numericValues
            // this.$emit("getMarketValue", this.array)
            this.$emit("getMarketValue",  {object: this.array , index: this.index })
        },
        changeUnit(event, index){
            this.array[this.p -1][index].unit = event.target.value
            this.$emit("getMarketValue", {object:this.array, index: this.index }) 
        },
    },
    watch: {
    },
};

</script>

<style scoped>
.iconButtonPrev {
    color: black;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    left: -0%;
    top: 20%
}

.iconButtonNext {
    color: black;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    right: -0%;
    top: 20%
}

.iconButtonPrev:hover,
.iconButtonNext:hover {
    background-color: rgba(113, 113, 113, 0.1);
    color: black;
}
h5{
    font-size: 0.9rem;
}

/* inputText */
.summaryText {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    /* border-bottom: 0; */
    border-bottom: solid 1px rgb(192, 192, 192);
    border-radius: 0px;
}
/* Chrome, Safari , Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    /* not native */
    /* margin: 0.3rem; */
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
    /* not native */
}
</style>
