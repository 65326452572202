<template >
  <!-- field col-4 my-3 -->
  <div class="field col-4 my-3">
    <label for="program">Programme</label>
    <div class="d-flex align-items-center">
      <Dropdown 
        id="program" 
        v-model="newForm.documentProgramId" 
        :options="programs" 
        :filter="true" 
        :showClear="true"
        optionLabel="name" 
        optionValue="documentProgramId" 
        placeholder="Select a Programme"
        style="
          width:100%;
          max-width: 25rem;
        "
        @update:model-value="updateDocumentChangingProgram(newForm.documentProgramId)"
      >
      
        <template #option="slotProps">
          <div class="p-dropdown-program-option d-flex align-items-center">
            <span>{{ slotProps.option.name }}</span>
          </div>
        </template>
      </Dropdown>

      <div v-if="!newForm.documentProgramId">
        <Button 
          @click="openCreate" 
          icon="pi pi-plus" 
          class="p-button-success p-button-rounded ms-3"
          v-if="topic.topicId" 
        />
      </div>
      <div v-else>
        <Button 
          @click="openRedirectProgram" 
          icon="pi pi-reply" 
          class="p-button-info p-button-rounded ms-3"
          v-if="topic.topicId" 
        />
      </div>
      <!-- <div v-if="newForm">
        {{ newForm }}
      </div> -->
      <!-- <div v-if="programs">
        {{ programs[0] }}
      </div> -->
    </div>
  </div>


  <!-- Create -->
  <Dialog 
    v-model:visible="createDialog" 
    :style="{ width: '90%' }" 
    header="Create Programme" 
    :modal="true"
    class="p-fluid container-fluid"
  >
    <div class="row">
      <div class="col-12">
        <div class="field my-3">
          <label for="title">Name</label>
          <InputText 
            id="name" 
            v-model.trim="newProgramForm.name" 
            @blur="v$.newProgramForm.name.$touch" 
            required="true"
            autofocus :class="{ 'p-invalid': !newProgramForm.name }" />
          <small class="p-error" v-if="!newProgramForm.name">Name is required.</small>
        </div>

        <div class="field my-3">
          <label for="title"> Topic <strong>{{ getTopicName(topic.topicId)[0].name }}</strong> </label>
          <label for="title"></label>
        </div>

        <div class="field my-3">
          <div class="col-3">
            <div class="field my-3">
              <label for="date">ProgrammeDate</label>
              <!-- {{newProgramForm.programDate}} -->
              <Calendar 
                v-model="newProgramForm.programDate" 
                dateFormat="mm/dd/yy" 
              />
            </div>
          </div>
        </div>

        <div class="field my-3">
          <div class="col-3">
            <div class="field my-3">
              <label for="date">Country</label>
              <template v-if="newProgramForm.topicId">
                <!-- need Segmentation -->
                <div v-for="(segmentation, index) in segmentations" :key="segmentation.segmentationId" class="my-3">
                  <div v-if="segmentation.isRegional">
                    <label for="countryRegionName" style="margin-left:2rem">{{ segmentation.name }}</label>
                    <Dropdown 
                      v-model="newProgramForm.country[index]" 
                      :options="getCountries(index)" 
                      optionLabel="name"
                      optionValue="countryId" 
                      :filter="true" 
                      placeholder="select Country" 
                      style="width:70%"
                      @change="setSegmItem(index, $event)" 
                    />
                  </div>
                </div>

              </template>
              <template v-else>
                <small class="p-error">
                  Topic is Not Selected
                </small>
              </template>
            </div>
          </div>
        </div>

        <div class="field my-3">
          <div class="col-3">
            <div class="field my-3">
              <template v-if="newProgramForm.topicId">
                <div v-for="(segmentation, index) in segmentations" :key="segmentation.segmentationId" class="my-3">
                  <div v-if="!segmentation.isRegional">
                    <label for="segmentation"> Segmentations - {{ segmentation.name }}</label>
                    <Dropdown v-model="newProgramForm.segmentationItems[index]"
                      :options="segmentation.segmentationItems" optionLabel="name" optionValue="segmentationItemId"
                      placeholder="Select Segmentation Items" style="width: 70%" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="field my-3">
              <label for="notes">Notes</label>
              <Textarea 
                id="notes" 
                v-model.trim="newProgramForm.notes" 
                rows="5" 
                cols="30" 
              />
            </div>
          </div>
        </div>

      </div>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeDialog()" />
      <Button label="Save" icon="pi pi-check" class="p-button-text" :loading="createIsLoading"
        :disabled="this.v$.newProgramForm.$invalid || createIsLoading" @click="createProgram()" />
    </template>
  </Dialog>
  
  <!-- Redirect SaveChanges   -->
  <Dialog
    v-model:visible="saveChangesDialog"
    :style="{width: '90%'}"
    header="Redirect to Programmes"
    class="p-fluid container-fluid"
  >
    <div>
        Save Documents before redirect to programme
    </div>
    <template #footer>
      <Button 
        label="Cancel" 
        icon="pi pi-times" 
        class="p-button-text" 
        @click="closeDialog()" 
      />

      <Button
        label="Redirect Without Save"
        class="p-button-info"
        @click="redirectProgram()"
      >
      </Button>

      <Button 
        label="Save & Redirect" 
        class="p-button-success" 
        @click="redirectSaveProgram()" 
      />
    </template>
  </Dialog>

</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import Textarea from 'primevue/textarea';
import { useToast } from 'vue-toastification';
import { getSegmentations } from '../../../../segmentation/services/segmentation.service';
import { createProgram } from '../../../../program/services/program.service';

const toast = useToast();

export default {
  name: 'DocumentTopicProgram',
  components: {
    Dropdown,
    Button,
    InputText,
    Dialog,
    Calendar,
    Textarea,
  },
  props: ['programs', 'selectedProgram', 'topic','index'],
  emits: ['updateProgram','updateCreatedProgram','saveDocument','redirectProgram', 'syncCountryCompanyWithProgram'],
  setup: () => ({ v$: useVuelidate() }),
  computed: {
    topics() {
      return this.$store.getters.obtainTopics
    },
  },
  async mounted() {
    this.getCurrentDate()
    this.getTopic()
    this.getSegmentations()
  },
  data() {
    return {
      createDialog: false,
      createIsLoading: false,
      saveChangesDialog: false,

      segmentations: null,
      newForm: {
        documentProgramId: null,
      },
      newProgramForm: {
        name: null,
        programDate: null,
        country: [],
        region: [],
        notes: null,
        segmentationItems: [],
        topicId: null,
      },
      redirectDocumentProgramTopicId: null, 
    };
  },
  validations() {
    return {
      newForm: {
        name: { required },
      },
      newProgramForm: {
        name: { required },
        programDate: { required },
        // country: { required },
        // region: { required },
        // notes: { required },
        // segmentationItems: { required },
      },
    };
  },
  methods: {
    changeProgramredirectDocumentProgramTopicId(change){
      const a = this.programs.filter(a => a.documentProgramId === change.documentProgramId);
      // console.log('chanmge',a[0].documentProgramTopics[0].documentProgramTopicId);
      this.redirectDocumentProgramTopicId =a[0].documentProgramTopics[0].documentProgramTopicId
    },
    getTopic() {
      this.newProgramForm.topicId = this.topic

    },
    getTopicName(topicId) {
      const topicName = this.topics.filter(topic => topic.topicId === topicId)
      return topicName
    },
    async getSegmentations() {
      try {
        this.loading = true;
        this.segmentations = await getSegmentations(this.topic.topicId);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    getCurrentDate() {
      let date = new Date();
      // let day = date.getDate();
      // let month = date.getMonth() + 1;
      // let year = date.getFullYear();

      // let fullDate = `${month}/${day}/${year}`
      this.newProgramForm.programDate = date
    },
    getCountries(index) {
      let countriesList = []
      // countriesList.push(si.region.countries)
      this.segmentations.filter(se => se.isRegional)[index].segmentationItems.map(si => si.region.regionCountries.map(rc=> countriesList.push(rc.country)))
      return countriesList.flat()
    },
    setSegmItem(index, event) {
      const segmentationItemId = this.segmentations.filter(se => se.isRegional)[index].segmentationItems.filter(si => si.region.regionCountries.some(c => c.countryId === event.value)).map(filtered => filtered.segmentationItemId)
      const regionId = this.segmentations.filter(se => se.isRegional)[index].segmentationItems.filter(si => si.region.regionCountries.some(c => c.countryId === event.value)).map(filtered => filtered.region.regionId)
      this.newProgramForm.region[index] = regionId[0]
      this.newProgramForm.segmentationItems[index] = segmentationItemId[0]
    },
    async createProgram() {
      try {
        this.loading = true;
        let programForm = {
          'name': this.newProgramForm.name,
          'documentProgramTopics': []
        }
        let documentProgramTopics = {
          'topicId': this.newProgramForm.topicId.topicId,
          'programDate': this.newProgramForm.programDate,
          'notes': this.newProgramForm.notes,
          'docProgramTopicSegItems': []
        }

        let segmentationItems = []
        this.newProgramForm.segmentationItems.map((item, index) => {
            const regionCountry = {
              'regionId': this.newProgramForm.region[index],
              'countryId': this.newProgramForm.country[index],
            }
            if (!this.newProgramForm.country[index]) {
              const segmentationItems1 = {
                'segmentationItemId': item,
                'regionCountry': null,
              }
              segmentationItems[index] = segmentationItems1
            }
            else {
              const segmentationItems1 = {
                'segmentationItemId': item,
                'regionCountry': regionCountry,
              }
              segmentationItems[index] = segmentationItems1
            }
          }
        );
        documentProgramTopics.docProgramTopicSegItems= segmentationItems
        programForm.documentProgramTopics.push(documentProgramTopics)
        // console.log(JSON.stringify(programForm) )
        // await this.$store.dispatch('addNewProgram', programForm);
        const a =  await createProgram(programForm);
        this.$emit('updateCreatedProgram', a.data)
        this.clearForm()
        this.loading = false;
        this.closeDialog();

      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    clearForm(){
      this.newProgramForm.name = null;
      // this.newProgramForm.programDate = null;
      this.getCurrentDate()
      this.newProgramForm.country = [];
      this.newProgramForm.region = [];
      this.newProgramForm.notes = null;
      this.newProgramForm.segmentationItems = [];
      this.getCurrentDate()
    },
    openCreate() {
      this.createDialog = true;
    },
    openRedirectProgram(){
      // console.log('redirect to',this.redirectDocumentProgramTopicId );
      this.saveChangesDialog= true;
      // window.open(`../program/overview/${this.redirectDocumentProgramTopicId}`, '_blank')
    },

    redirectProgram(){
      this.$emit('redirectProgram',this.redirectDocumentProgramTopicId);
    },

    redirectSaveProgram(){
      this.saveDocument(this.redirectDocumentProgramTopicId)
      // this.$router.push(`../../program/overview/${this.redirectDocumentProgramTopicId}` , '_blank')
      // window.open(`../../program/overview/${this.redirectDocumentProgramTopicId}`, '_blank')
      // this.closeDialog();
    },
    saveDocument(redirectDocumentProgramTopicId){
      this.$emit('saveDocument',redirectDocumentProgramTopicId);
    },

    closeDialog() {
      this.createDialog = false;
      this.saveChangesDialog = false; 
    },
    updateProgram() {
      this.$emit('updateProgram', this.newForm);
    },
    updateDocumentChangingProgram(documentProgramId){
      if(documentProgramId){
        const programs = this.programs.filter( (program  ) => program.documentProgramId === documentProgramId )
        const manufactured = programs[0].documentProgramTopics[0].manufacturerId

        // countryManufactured
        // const contryManufactured = programs[0].documentProgramTopics[0].countryManufactured 

        // Log Test
        // console.log("send country and Manufacturer", manufacturer, contryManufactured);



        // GET SEGMENTATION COUNTRY ID
        let countryId;
        // validation if segmentations ItemsExists

        try {
          if(programs[0].documentProgramTopics[0].docProgramTopicSegItems.length > 0){
            const programSegmentationCountry = programs[0].documentProgramTopics[0].docProgramTopicSegItems.filter(docProgramTopicSegItem => docProgramTopicSegItem.regionCountry != null )
            // validation if segmentationsItem region exist
            if(programSegmentationCountry){
              countryId = programSegmentationCountry[0].regionCountry.countryId
            }
            else{
              countryId = null
            }
          }
        } catch (error) {
          countryId = null
        }

        // Log Test
        // console.log("send country and Manufacturer", manufacturer, countryId);

        this.$emit("syncCountryCompanyWithProgram", {manufactured, countryId})
      }
    }
  },
  watch: {
    'newForm.documentProgramId': function (newValue) {
      if (newValue) {
        this.updateProgram();
      }
    },
    selectedProgram(newValue) {
      if (newValue) {
        // console.log('selectedProgram', newValue);
        this.changeProgramredirectDocumentProgramTopicId(newValue)
        this.newForm = newValue;
      }
    },
  },
};
</script>
